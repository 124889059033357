/* Default bullet style */
.swiper-container.default .swiper-pagination .swiper-pagination-bullet,
.swiper-container.default .swiper-pagination .swiper-pagination-bullet-active,
.swiper-container.default .swiper-pagination .swiper-pagination-bullet-prev,
.swiper-container.default .swiper-pagination .swiper-pagination-bullet-next {
  width: 10px;
  height: 10px;
  opacity: 1;
  border: #000 solid 1.5px;
  background-color: transparent;
}

.swiper-container.default .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #000;
}

/* White bullet style */
.swiper-container.white .swiper-pagination .swiper-pagination-bullet,
.swiper-container.white .swiper-pagination .swiper-pagination-bullet-active,
.swiper-container.white .swiper-pagination .swiper-pagination-bullet-prev,
.swiper-container.white .swiper-pagination .swiper-pagination-bullet-next {
  width: 10px;
  height: 10px;
  opacity: 1;
  border: #fff solid 1.5px;
  background-color: transparent;
}

.swiper-container.white .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper.swiper-container {
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}

.swiper-wrapper {
  max-height: 100% !important; 
  max-width: 100% !important;
  display: flex;
}

/* .swiper-wrapper-custom {
  max-height: 100% !important; 
  height: 100% !important;
  display: flex;
} */

.swiper-slide {
  display: grid;
  place-items: center;
}

.swiper-slide-custom {
  /* height: auto !important; */
  width: 100% !important;
  /* margin-right: 8px !important; */
}

.swiper-pagination {
  z-index: 1 !important;
}